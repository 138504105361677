import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import useAuth from 'contexts/auth/auth.context';
import { getCurrentUser } from 'helper/user';
import { LOGIN_ROUTE } from 'constants/navigation';

export function ProtectedRoute(PageComponent, props) {
  return () => {
    const { authState } = useAuth();
    const { user } = authState;
    const router = useRouter()

    useEffect(() => {
      if (!user) router.push(LOGIN_ROUTE);
    }, [authState])
    
    if (user && getCurrentUser()) {
      return <PageComponent {...props} />;
    } else {
      return <></>;
      // return <div><Loading />;</div>
    }
  }
};

export default ProtectedRoute;